import { EditorProvider, useCurrentEditor } from '@tiptap/react'
import React, { useEffect, useState } from 'react'
import { SubstituteInterface } from 'shared/components/dashboard-mail-editor/components/substitutes-block'
import { useSubstitutes } from 'shared/components/dashboard-mail-editor/hooks/use-substitutes'
import useUser from 'shared/hooks/use-user'
import { twMerge } from 'tailwind-merge'
import EditorSkeleton from './components/editor-skeleton'
import Footer from './components/footer'
import LinkBubbleMenu from './components/link-bubble-menu'
import SelectionBubbleMenu from './components/selection-bubble-menu'
import Toolbar from './components/toolbar'
import { defaultExtensions } from './constans/default-extensions'
import { EditorExtensionEnum } from './enums/editor-extensions-enum'
import { getExistingExtensions, getExtensions } from './utils/get-extensions'

export interface TipTapEditorProps {
  content: string | undefined
  isPreFetching?: boolean
  isEditable?: boolean
  showFooter?: boolean
  isError?: boolean
  onChange: (data: string) => void
  classNameSkeleton?: string
  additionalSubstitutes?: SubstituteInterface[]
  withoutSubstitutes?: boolean
  extensions?: EditorExtensionEnum[]
}

const TipTapEditor: React.FC<TipTapEditorProps> = ({
  content,
  isPreFetching,
  isEditable = true,
  isError,
  onChange,
  showFooter,
  classNameSkeleton,
  additionalSubstitutes,
  withoutSubstitutes,
  extensions = defaultExtensions,
}) => {
  const { editor } = useCurrentEditor()
  const { substitutionData, isReady } = useSubstitutes(withoutSubstitutes, additionalSubstitutes)
  const { user } = useUser()

  const [editorLoaded, setEditorLoaded] = useState(false)

  useEffect(() => {
    if (!isReady) return
    setEditorLoaded(true)
  }, [isReady])

  useEffect(() => {
    if (editor && content) {
      editor.commands.setContent(content)
    }
  }, [content, editor])

  const { hasLink } = getExistingExtensions(extensions)

  return (
    <div
      className={twMerge(
        'relative flex h-full flex-col',
        isError ? 'border border-danger rounded-lg' : 'border border-transparent rounded-lg',
      )}
    >
      {editorLoaded && user ? (
        <EditorProvider
          slotBefore={
            extensions.length !== 0 && (
              <Toolbar substitutions={substitutionData} extensions={extensions} />
            )
          }
          extensions={getExtensions([
            EditorExtensionEnum.Document,
            EditorExtensionEnum.Paragraph,
            EditorExtensionEnum.Text,
            ...extensions,
          ])}
          content={content}
          editable={isEditable || !isPreFetching}
          onUpdate={({ editor }) => {
            onChange(editor.getHTML())
          }}
          onCreate={({ editor }) => {
            editor.view.dom.classList.add(
              'border',
              'border-t-0',
              'border-gray/30',
              'min-h-[500px]',
              'w-full',
              'px-[9px]',
              'py-[1px]',
              'focus:outline-none',
              'focus:border-blue',
              'focus:border-t',
              'font-times',
            )
          }}
          slotAfter={showFooter && <Footer />}
        >
          {hasLink && <LinkBubbleMenu />}
          <SelectionBubbleMenu extensions={extensions} />
        </EditorProvider>
      ) : (
        <EditorSkeleton classNameSkeleton={classNameSkeleton} showFooter={showFooter} />
      )}
    </div>
  )
}

export default TipTapEditor
