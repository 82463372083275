import { useEmailOptions } from 'shared/components/dashboard-mail-editor/hooks/use-email-options'

const Footer = () => {
  const { data: options } = useEmailOptions()

  return (
    <div className={'p-5 border border-gray/30 rounded-b-lg border-t-transparent bg-blue-100'}>
      {options?.emailFooter}
    </div>
  )
}

export default Footer
