import { Editor } from '@tiptap/react'
import { twMerge } from 'tailwind-merge'
import { TextAlignEnum } from '../enums/text-align-enum'
import AlignCenterIcon from '../icons/align-center-icon'
import AlignJustifyIcon from '../icons/align-justify-icon'
import AlignLeftIcon from '../icons/align-left-icon'
import AlignRightIcon from '../icons/align-right-icon'

export const getTextAlignValues = (selectedValue?: TextAlignEnum) => {
  const getCaptionIcon = (key: TextAlignEnum) => {
    const className = twMerge('hover:fill-white', selectedValue === key && 'fill-white')

    switch (key) {
      case TextAlignEnum.Left:
        return <AlignLeftIcon className={className} />
      case TextAlignEnum.Right:
        return <AlignRightIcon className={className} />
      case TextAlignEnum.Center:
        return <AlignCenterIcon className={className} />
      case TextAlignEnum.Justify:
        return <AlignJustifyIcon className={className} />
    }
  }

  return Object.values(TextAlignEnum).map(key => ({
    id: key,
    caption: key,
    customCaption: getCaptionIcon(key),
  }))
}

export const getCurrentTextAlignValue = (editor: Editor | null): TextAlignEnum | undefined => {
  if (!editor) return undefined

  const activeValue = getTextAlignValues().find(({ id }) => editor.isActive({ textAlign: id }))

  return activeValue ? activeValue.id : undefined
}
