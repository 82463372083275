import { getEmailNewEditorUrl } from 'modules/email/utils/get-email-new-editor-url'
import { MailingId } from 'modules/mailing/types/mailing-interface'
import React, { useEffect, useState } from 'react'
import Frame, { FrameContextConsumer } from 'react-frame-component'
import useMediaQuery from 'shared/hooks/use-media-query'
import { twJoin } from 'tailwind-merge'
import { ExtendedState } from '../../types/common'

export type NewEditorProps = {
  tempState: ExtendedState
  isPreFetching?: boolean
  mailingId: MailingId
  // TODO: remove prop after migration to tip-tap editor
  tempNewEditorRecalculateHeightCondition?: boolean
}

export const NewEditor = ({
  tempState,
  isPreFetching,
  mailingId,
  tempNewEditorRecalculateHeightCondition,
}: NewEditorProps) => {
  const [height, setHeight] = useState(0)
  const { isLG } = useMediaQuery()

  return (
    <Frame
      style={{ height: isLG ? height : 'inherit' }}
      className={twJoin(`w-full`, isPreFetching && 'pointer-events-none')}
    >
      <FrameContextConsumer>
        {({ document }) => (
          <FrameContent
            document={document}
            setHeight={setHeight}
            tempState={tempState}
            mailingId={mailingId}
            tempNewEditorRecalculateHeightCondition={tempNewEditorRecalculateHeightCondition}
          />
        )}
      </FrameContextConsumer>
    </Frame>
  )
}

interface FrameContentProps extends Pick<NewEditorProps, 'tempState' | 'mailingId'> {
  document?: Document
  setHeight: (height: number) => void
  // TODO: remove prop after migration to tip-tap editor
  tempNewEditorRecalculateHeightCondition?: boolean
}

const FrameContent = ({
  tempState,
  mailingId,
  setHeight,
  document,
  tempNewEditorRecalculateHeightCondition,
}: FrameContentProps) => {
  useEffect(() => {
    const height = document?.body.clientHeight
    if (!height) return
    setHeight(height)
  }, [document, setHeight, tempNewEditorRecalculateHeightCondition])

  return (
    <>
      <a
        style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}
        target={'_parent'}
        href={getEmailNewEditorUrl(mailingId)}
      />
      <div dangerouslySetInnerHTML={{ __html: tempState.html ?? '' }} />
    </>
  )
}
