import { BubbleMenu, useCurrentEditor } from '@tiptap/react'
import React, { FC } from 'react'
import LinkButton from '../components/content-controls//link-button'
import TextColorButton from '../components/content-controls//text-color-button'
import TextFormatButtons from '../components/content-controls//text-format-buttons'
import { EditorExtensionEnum } from '../enums/editor-extensions-enum'
import { getExistingExtensions } from '../utils/get-extensions'

interface Props {
  extensions: EditorExtensionEnum[]
}

const SelectionBubbleMenu: FC<Props> = ({ extensions }) => {
  const { editor } = useCurrentEditor()

  const { hasLink, hasBold, hasItalic, hasUnderline, hasStrike, hasColor } =
    getExistingExtensions(extensions)

  if (!hasLink && !hasBold && !hasItalic && !hasUnderline && !hasStrike && !hasColor) return null

  return (
    <BubbleMenu
      className="flex items-center gap-2 p-2 bg-white rounded-lg shadow-lg border border-gray-200"
      tippyOptions={{
        duration: 0,
        placement: 'bottom',
        offset: [0, 10],
        followCursor: true,
        hideOnClick: true,
      }}
      editor={editor}
      shouldShow={({ state, editor }) => {
        const { selection } = state
        const { empty } = selection

        return !empty && !editor.isActive('link')
      }}
    >
      {hasLink && <LinkButton />}
      <TextFormatButtons extensions={extensions} />
      {hasColor && <TextColorButton />}
    </BubbleMenu>
  )
}

export default SelectionBubbleMenu
