import { Tooltip } from '@systemeio/ui-shared'
import { ArrowDownIcon, NeutralButton } from '@systemeio/ui-shared'
import { useCurrentEditor } from '@tiptap/react'
import React, { FC } from 'react'
import { ListBoxSelect } from 'shared/components/form-select/listbox-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { DEFAULT_FONT_SIZE, TOOLTIP_STYLES } from '../../constans/default-styles'
import TextHeightIcon from '../../icons/text-height-icon'
import { getFontSizeValues } from '../../utils/font-size'

interface Props {
  className?: string
}

const FontSizeSelect: FC<Props> = ({ className }) => {
  const { editor } = useCurrentEditor()
  const { t } = useLocoTranslation()

  const onChangeFontSize = (value: string | undefined) => {
    if (!editor || !value) return null

    setTimeout(() => {
      editor.commands.focus()
    }, 0)

    editor?.commands.setFontSize(value)
  }

  const selectedFontSize: string | undefined = editor?.getAttributes('textStyle').fontSize

  const isDisabled = !editor?.options.editable

  return (
    <div className={className}>
      <Tooltip
        label={t('dashboard.simple_mail_editor.font_size')}
        anchor="left"
        {...TOOLTIP_STYLES}
      >
        <ListBoxSelect
          disabled={isDisabled}
          value={editor?.getAttributes('textStyle').fontSize}
          onChange={onChangeFontSize}
          data={getFontSizeValues()}
        >
          {open => (
            <NeutralButton
              disabled={isDisabled}
              className={twMerge('w-[110px]', isDisabled && 'text-gray')}
            >
              <TextHeightIcon className={twMerge(isDisabled && 'fill-gray')} />
              {selectedFontSize ?? DEFAULT_FONT_SIZE}
              <ArrowDownIcon
                className={twMerge(
                  'transition-transform ease-linear duration-200',
                  open && 'rotate-180',
                  isDisabled && 'stroke-gray',
                )}
              />
            </NeutralButton>
          )}
        </ListBoxSelect>
      </Tooltip>
    </div>
  )
}

export default FontSizeSelect
