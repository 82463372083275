import { Tooltip } from '@systemeio/ui-shared'
import { ArrowDownIcon, NeutralButton } from '@systemeio/ui-shared'
import { Level } from '@tiptap/extension-heading'
import { useCurrentEditor } from '@tiptap/react'
import React from 'react'
import { ListBoxSelect } from 'shared/components/form-select/listbox-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { TOOLTIP_STYLES } from '../../constans/default-styles'
import { TextLevelEnum } from '../../enums/text-level-enum'
import { useShortcuts } from '../../hooks/use-shortcuts'
import HeadingIcon from '../../icons/heading-icon'
import { getCurrentTextLevelValue, getTextLevelValues } from '../../utils/text-level'

interface Props {
  className?: string
}

const TextLevelSelect: React.FC<Props> = ({ className }) => {
  const { editor } = useCurrentEditor()
  const { getShortcut, rawShortcuts } = useShortcuts()
  const { t } = useLocoTranslation()

  const onChangeTextLevel = (value: TextLevelEnum | undefined) => {
    if (!editor) return null

    setTimeout(() => {
      editor.commands.focus()
    }, 0)

    if (value === TextLevelEnum.Default) {
      return editor.commands.setParagraph()
    }

    editor.commands.toggleHeading({ level: value as Level })
  }

  const currentCaption = getTextLevelValues(t).find(
    i => i.id === getCurrentTextLevelValue(editor),
  )?.caption

  const isDisabled = !editor?.options.editable

  return (
    <div className={className}>
      <Tooltip
        label={t('dashboard.simple_mail_editor.text_level')}
        anchor="left"
        {...TOOLTIP_STYLES}
      >
        <ListBoxSelect
          disabled={isDisabled}
          value={getCurrentTextLevelValue(editor)}
          onChange={onChangeTextLevel}
          data={getTextLevelValues(t)}
        >
          {open => (
            <NeutralButton
              disabled={isDisabled}
              className={twMerge('w-[155px]', isDisabled && 'text-gray')}
            >
              <HeadingIcon className={twMerge(isDisabled && 'fill-gray')} />
              {currentCaption}
              <ArrowDownIcon
                className={twMerge(
                  'transition-transform ease-linear duration-200',
                  open && 'rotate-180',
                  isDisabled && 'stroke-gray',
                )}
              />
            </NeutralButton>
          )}
        </ListBoxSelect>
      </Tooltip>
    </div>
  )
}

export default TextLevelSelect
