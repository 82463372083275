import React, { FC } from 'react'
import { TipTapEditorProps } from 'shared/components/dashboard-mail-editor/components/tip-tap-editor/index'
import { useEmailOptions } from 'shared/components/dashboard-mail-editor/hooks/use-email-options'
import { twMerge } from 'tailwind-merge'

interface Props extends Pick<TipTapEditorProps, 'showFooter' | 'classNameSkeleton'> {}

const EditorSkeleton: FC<Props> = ({ showFooter, classNameSkeleton }) => {
  const { data: options } = useEmailOptions(!!showFooter)
  return (
    <div
      className={twMerge(
        'animate-pulse h-[540px] bg-gray-600/40 text-transparent',
        options ? 'rounded-t-lg' : 'rounded-lg',
        classNameSkeleton,
      )}
    >
      .
    </div>
  )
}

export default EditorSkeleton
