import { Editor } from '@tiptap/react'

export const isCursorInsideLink = (editor: Editor | null) => {
  if (!editor) return false

  const { from, to } = editor.state.selection
  let isInsideLink = false

  editor.state.doc.nodesBetween(from, to, node => {
    if (node.isText) {
      const mark = node.marks.find(mark => mark.type.name === 'link')
      if (mark) {
        isInsideLink = true
      }
    }
  })

  return isInsideLink
}
