import { Editor } from '@tiptap/react'
import { ListEnum } from '../enums/list-enum'
import ListOlIcon from '../icons/list-ol-icon'
import ListUlIcon from '../icons/list-ul-icon'

export const setList = ({ listType, editor }: { listType: ListEnum; editor: Editor | null }) => {
  if (!editor) return

  switch (listType) {
    case ListEnum.BulletList:
      editor.chain().focus().toggleBulletList().run()
      break
    case ListEnum.OrderedList:
      editor.chain().focus().toggleOrderedList().run()
      break
  }
}

export const getListIcon = ({ listType }: { listType: ListEnum }) => {
  switch (listType) {
    case ListEnum.BulletList:
      return ListUlIcon
    case ListEnum.OrderedList:
      return ListOlIcon
  }
}

export const getBulletListStyleType = (depth: number): string => {
  switch (depth) {
    case 0:
      return 'disc'
    case 1:
      return 'circle'
    default:
      return 'square'
  }
}

export const getOrderedListStyleType = (depth: number): string => {
  switch (depth) {
    case 0:
      return 'decimal'
    case 1:
      return 'lower-latin'
    case 2:
      return 'lower-roman'
    case 3:
      return 'upper-latin'
    default:
      return 'upper-roman'
  }
}
