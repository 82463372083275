export enum FontFamilyEnum {
  Default = 'Default',
  Arial = 'Arial, sans-serif',
  Verdana = 'Verdana, sans-serif',
  Tahoma = 'Tahoma, sans-serif',
  TrebuchetMS = 'Trebuchet MS, sans-serif',
  TimesNewRoman = 'Times New Roman, serif',
  Georgia = 'Georgia, serif',
  Garamond = 'Garamond, serif',
  CourierNew = 'Courier New, monospace',
  BrushScriptMT = 'Brush Script MT, cursive',
}
