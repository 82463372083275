import { ListEnum } from '../enums/list-enum'
import { TextAlignEnum } from '../enums/text-align-enum'
import { TextFormatEnum } from '../enums/text-format-enum'
import { TextLevelEnum } from '../enums/text-level-enum'
import { getOS } from '../utils/get-os'

export const useShortcuts = () => {
  const rawShortcuts = {
    [TextFormatEnum.Bold]: {
      toggle: 'Mod-B',
    },
    [TextFormatEnum.Italic]: {
      toggle: 'Mod-I',
    },
    [TextFormatEnum.Strike]: {
      toggle: 'Mod-Shift-S',
    },
    [TextFormatEnum.Underline]: {
      toggle: 'Mod-U',
    },
    textAlign: {
      [TextAlignEnum.Left]: 'Mod-Shift-L',
      [TextAlignEnum.Center]: 'Mod-Shift-E',
      [TextAlignEnum.Right]: 'Mod-Shift-R',
      [TextAlignEnum.Justify]: 'Mod-Shift-J',
    },
    list: {
      [ListEnum.OrderedList]: 'Mod-Shift-7',
      [ListEnum.BulletList]: 'Mod-Shift-8',
    },
    heading: {
      [TextLevelEnum.Default]: 'Mod-Alt-0',
      [TextLevelEnum.H2]: 'Mod-Alt-2',
      [TextLevelEnum.H3]: 'Mod-Alt-3',
      [TextLevelEnum.H4]: 'Mod-Alt-4',
    },
    history: {
      undo: 'Mod-Z',
      redo: 'Shift-Mod-Z',
    },
  } as const

  const getShortcut = (rawShortcut: string) => {
    const os = getOS()
    const shortcut = rawShortcut
      .replaceAll('Mod', os === 'macOS' ? '⌘' : 'Ctrl')
      .replaceAll('Alt', os === 'macOS' ? '⌥' : 'Alt')
      .replaceAll('-', ' ')

    return shortcut
  }

  return {
    rawShortcuts,
    getShortcut,
  }
}
