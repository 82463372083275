import { NeutralButton, Tooltip, UndoIcon } from '@systemeio/ui-shared'
import { useCurrentEditor } from '@tiptap/react'
import { FC } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { TOOLTIP_STYLES } from '../../constans/default-styles'
import { useShortcuts } from '../../hooks/use-shortcuts'

interface Props {
  className?: string
}

const HistoryButtons: FC<Props> = ({ className }) => {
  const { editor } = useCurrentEditor()
  const { getShortcut, rawShortcuts } = useShortcuts()
  const { t } = useLocoTranslation()

  const isDisabled = !editor?.options.editable

  return (
    <div className={twMerge('flex gap-1', className)}>
      <Tooltip
        label={`${t('dashboard.simple_mail_editor.undo')} (${getShortcut(
          rawShortcuts.history.undo,
        )})`}
        {...TOOLTIP_STYLES}
      >
        <NeutralButton
          onClick={() => editor?.chain().focus().undo().run()}
          disabled={isDisabled || !editor?.can().undo()}
        >
          <UndoIcon className={!editor?.can().undo() ? 'fill-gray' : 'fill-black'} />
        </NeutralButton>
      </Tooltip>
      <Tooltip
        label={`${t('dashboard.simple_mail_editor.redo')} (${getShortcut(
          rawShortcuts.history.redo,
        )})`}
        {...TOOLTIP_STYLES}
      >
        <NeutralButton
          onClick={() => editor?.chain().focus().redo().run()}
          disabled={isDisabled || !editor?.can().redo()}
        >
          <UndoIcon
            className={twMerge(
              'transform -scale-x-100',
              !editor?.can().redo() ? 'fill-gray' : 'fill-black',
            )}
          />
        </NeutralButton>
      </Tooltip>
    </div>
  )
}

export default HistoryButtons
