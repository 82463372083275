import { EditorExtensionEnum } from '../enums/editor-extensions-enum'

export const defaultExtensions = [
  EditorExtensionEnum.Document,
  EditorExtensionEnum.Paragraph,
  EditorExtensionEnum.Text,
  EditorExtensionEnum.TextStyle,
  EditorExtensionEnum.FontFamily,
  EditorExtensionEnum.Heading,
  EditorExtensionEnum.Bold,
  EditorExtensionEnum.Italic,
  EditorExtensionEnum.Strike,
  EditorExtensionEnum.Underline,
  EditorExtensionEnum.BulletList,
  EditorExtensionEnum.OrderedList,
  EditorExtensionEnum.ListItem,
  EditorExtensionEnum.TextAlign,
  EditorExtensionEnum.Link,
  EditorExtensionEnum.History,
  EditorExtensionEnum.Color,
  EditorExtensionEnum.BackgroundColor,
  EditorExtensionEnum.FontSize,
  EditorExtensionEnum.Indent,
  EditorExtensionEnum.HardBreak,
  EditorExtensionEnum.Substitution,
  EditorExtensionEnum.SpecialCharacters,
]
