const specialCurrencyCharactersData = [
  {
    name: 'dashboard.email.editor.special_characters.dollar',
    code: 'U+0024',
    symbol: '$',
  },
  {
    name: 'dashboard.email.editor.special_characters.euro',
    code: 'U+20ac',
    symbol: '€',
  },
  {
    name: 'dashboard.email.editor.special_characters.yen',
    code: 'U+00a5',
    symbol: '¥',
  },
  {
    name: 'dashboard.email.editor.special_characters.pound',
    code: 'U+00a3',
    symbol: '£',
  },
  {
    name: 'dashboard.email.editor.special_characters.cent',
    code: 'U+00a2',
    symbol: '¢',
  },
  {
    name: 'dashboard.email.editor.special_characters.euro_currency',
    code: 'U+20a0',
    symbol: '₠',
  },
  {
    name: 'dashboard.email.editor.special_characters.colon',
    code: 'U+20a1',
    symbol: '₡',
  },
  {
    name: 'dashboard.email.editor.special_characters.cruzeiro',
    code: 'U+20a2',
    symbol: '₢',
  },
  {
    name: 'dashboard.email.editor.special_characters.french_franc',
    code: 'U+20a3',
    symbol: '₣',
  },
  {
    name: 'dashboard.email.editor.special_characters.lira',
    code: 'U+20a4',
    symbol: '₤',
  },
  {
    name: 'dashboard.email.editor.special_characters.currency',
    code: 'U+00a4',
    symbol: '¤',
  },
  {
    name: 'dashboard.email.editor.special_characters.bitcoin',
    code: 'U+20bf',
    symbol: '₿',
  },
  {
    name: 'dashboard.email.editor.special_characters.mill',
    code: 'U+20a5',
    symbol: '₥',
  },
  {
    name: 'dashboard.email.editor.special_characters.mill',
    code: 'U+20a6',
    symbol: '₦',
  },
  {
    name: 'dashboard.email.editor.special_characters.peseta',
    code: 'U+20a7',
    symbol: '₧',
  },
  {
    name: 'dashboard.email.editor.special_characters.rupee',
    code: 'U+20a8',
    symbol: '₨',
  },
  {
    name: 'dashboard.email.editor.special_characters.won',
    code: 'U+20a9',
    symbol: '₩',
  },
  {
    name: 'dashboard.email.editor.special_characters.new_sheqel',
    code: 'U+20aa',
    symbol: '₪',
  },
  {
    name: 'dashboard.email.editor.special_characters.dong',
    code: 'U+20ab',
    symbol: '₫',
  },
  {
    name: 'dashboard.email.editor.special_characters.kip',
    code: 'U+20ad',
    symbol: '₭',
  },
  {
    name: 'dashboard.email.editor.special_characters.tugrik',
    code: 'U+20ae',
    symbol: '₮',
  },
  {
    name: 'dashboard.email.editor.special_characters.drachma',
    code: 'U+20af',
    symbol: '₯',
  },
  {
    name: 'dashboard.email.editor.special_characters.german_penny',
    code: 'U+20b0',
    symbol: '₰',
  },
  {
    name: 'dashboard.email.editor.special_characters.peso',
    code: 'U+20b1',
    symbol: '₱',
  },
  {
    name: 'dashboard.email.editor.special_characters.guarani',
    code: 'U+20b2',
    symbol: '₲',
  },
  {
    name: 'dashboard.email.editor.special_characters.austral',
    code: 'U+20b3',
    symbol: '₳',
  },
  {
    name: 'dashboard.email.editor.special_characters.hryvnia',
    code: 'U+20b4',
    symbol: '₴',
  },
  {
    name: 'dashboard.email.editor.special_characters.cedi',
    code: 'U+20b5',
    symbol: '₵',
  },
  {
    name: 'dashboard.email.editor.special_characters.livre_tournois',
    code: 'U+20b6',
    symbol: '₶',
  },
  {
    name: 'dashboard.email.editor.special_characters.spesmilo',
    code: 'U+20b7',
    symbol: '₷',
  },
  {
    name: 'dashboard.email.editor.special_characters.tenge',
    code: 'U+20b8',
    symbol: '₸',
  },
  {
    name: 'dashboard.email.editor.special_characters.indian_rupee',
    code: 'U+20b9',
    symbol: '₹',
  },
  {
    name: 'dashboard.email.editor.special_characters.turkish_lira',
    code: 'U+20ba',
    symbol: '₺',
  },
  {
    name: 'dashboard.email.editor.special_characters.nordic_mark',
    code: 'U+20bb',
    symbol: '₻',
  },
  {
    name: 'dashboard.email.editor.special_characters.manat',
    code: 'U+20bc',
    symbol: '₼',
  },
  {
    name: 'dashboard.email.editor.special_characters.ruble',
    code: 'U+20bd',
    symbol: '₽',
  },
]

const specialTextCharactersData = [
  {
    name: 'dashboard.email.editor.special_characters.left_pointing_angle_quotation_mark',
    code: 'U+2039',
    symbol: '‹',
  },
  {
    name: 'dashboard.email.editor.special_characters.right_pointing_angle_quotation_mark',
    code: 'U+203a',
    symbol: '›',
  },
  {
    name: 'dashboard.email.editor.special_characters.left_pointing_double_angle_quotation_mark',
    code: 'U+00ab',
    symbol: '«',
  },
  {
    name: 'dashboard.email.editor.special_characters.right_pointing_double_angle_quotation_mark',
    code: 'U+00bb',
    symbol: '»',
  },
  {
    name: 'dashboard.email.editor.special_characters.left_single_quotation_mark',
    code: 'U+2018',
    symbol: '‘',
  },
  {
    name: 'dashboard.email.editor.special_characters.right_single_quotation_mark',
    code: 'U+2019',
    symbol: '’',
  },
  {
    name: 'dashboard.email.editor.special_characters.left_double_quotation_mark',
    code: 'U+201c',
    symbol: '“',
  },
  {
    name: 'dashboard.email.editor.special_characters.right_double_quotation_mark',
    code: 'U+201d',
    symbol: '”',
  },
  {
    name: 'dashboard.email.editor.special_characters.single_low_quotation_mark',
    code: 'U+201a',
    symbol: '‚',
  },
  {
    name: 'dashboard.email.editor.special_characters.double_low_quotation_mark',
    code: 'U+201e',
    symbol: '„',
  },
  {
    name: 'dashboard.email.editor.special_characters.inverted_exclamation_mark',
    code: 'U+00a1',
    symbol: '¡',
  },
  {
    name: 'dashboard.email.editor.special_characters.inverted_question_mark',
    code: 'U+00bf',
    symbol: '¿',
  },
  {
    name: 'dashboard.email.editor.special_characters.two_dot_leader',
    code: 'U+2025',
    symbol: '‥',
  },
  {
    name: 'dashboard.email.editor.special_characters.horizontal_ellipsis',
    code: 'U+2026',
    symbol: '…',
  },
  {
    name: 'dashboard.email.editor.special_characters.double_dagger',
    code: 'U+2021',
    symbol: '‡',
  },
  {
    name: 'dashboard.email.editor.special_characters.per_mille_sign',
    code: 'U+2030',
    symbol: '‰',
  },
  {
    name: 'dashboard.email.editor.special_characters.per_ten_thousand_sign',
    code: 'U+2031',
    symbol: '‱',
  },
  {
    name: 'dashboard.email.editor.special_characters.double_exclamation_mark',
    code: 'U+203c',
    symbol: '‼',
  },
  {
    name: 'dashboard.email.editor.special_characters.question_exclamation_mark',
    code: 'U+2048',
    symbol: '⁈',
  },
  {
    name: 'dashboard.email.editor.special_characters.exclamation_question_mark',
    code: 'U+2049',
    symbol: '⁉',
  },
  {
    name: 'dashboard.email.editor.special_characters.double_question_mark',
    code: 'U+2047',
    symbol: '⁇',
  },
  {
    name: 'dashboard.email.editor.special_characters.copyright_sign',
    code: 'U+00a9',
    symbol: '©',
  },
  {
    name: 'dashboard.email.editor.special_characters.registered_sign',
    code: 'U+00ae',
    symbol: '®',
  },
  {
    name: 'dashboard.email.editor.special_characters.trade_mark_sign',
    code: 'U+2122',
    symbol: '™',
  },
  {
    name: 'dashboard.email.editor.special_characters.section_sign',
    code: 'U+00a7',
    symbol: '§',
  },
  {
    name: 'dashboard.email.editor.special_characters.paragraph_sign',
    code: 'U+00b6',
    symbol: '¶',
  },
  {
    name: 'dashboard.email.editor.special_characters.reversed_paragraph_sign',
    code: 'U+204b',
    symbol: '⁋',
  },
]

export const specialCharactersData = {
  currency: specialCurrencyCharactersData,
  text: specialTextCharactersData,
  all: [...specialCurrencyCharactersData, ...specialTextCharactersData],
}
