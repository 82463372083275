import { FormSelect, Tooltip } from '@systemeio/ui-shared'
import { useCurrentEditor } from '@tiptap/react'
import React from 'react'
import { SubstitutionData } from 'shared/components/dashboard-mail-editor/hooks/use-substitutes'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { TOOLTIP_STYLES } from '../../constans/default-styles'

interface Props {
  substitutions: SubstitutionData[]
  className?: string
}

const SubstitutionSelect: React.FC<Props> = ({ substitutions, className }) => {
  const { t } = useLocoTranslation()
  const { editor } = useCurrentEditor()

  const onChange = (value: string | undefined) => {
    if (!editor) return null

    setTimeout(() => {
      editor.commands.focus()
    }, 0)

    value && editor.commands.insertContent(value)
  }

  return (
    <div className={className}>
      <Tooltip label={t('dashboard.email.substitute_variables')} anchor="left" {...TOOLTIP_STYLES}>
        <FormSelect
          className="w-[210px] placeholder:text-darkblue focus:placeholder:text-gray-300/70"
          placeholder={t('dashboard.email.substitute_variables')}
          value={undefined}
          data={substitutions.map(({ label, data }) => ({ id: data, caption: label }))}
          onChange={onChange}
        />
      </Tooltip>
    </div>
  )
}

export default SubstitutionSelect
