import React, { Hint } from '@systemeio/ui-shared'
import { TLocoType } from 'shared/hooks/use-loco-translation'
import { FontFamilyEnum } from '../enums/font-family-enum'

export const getFontFamilyValues = (t: TLocoType) =>
  Object.values(FontFamilyEnum).map(key => {
    const caption = key.split(',')[0]

    return {
      id: key,
      caption,
      customCaption: (
        <div style={{ fontFamily: key }}>
          {caption === FontFamilyEnum.Default ? (
            <Hint label={t('dashboard.email.editor.font_family.hint')}>
              {t('dashboard.email.editor.font_family.default')}
            </Hint>
          ) : (
            caption
          )}
        </div>
      ),
    }
  })
