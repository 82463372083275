import { NeutralButton, PrimaryButton, Tooltip } from '@systemeio/ui-shared'
import { useCurrentEditor } from '@tiptap/react'
import { FC } from 'react'
import React from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { TOOLTIP_STYLES } from '../../constans/default-styles'
import LinkIcon from '../../icons/link-icon'
import { isCursorInsideLink } from '../../utils/link'

interface Props {
  className?: string
}

const LinkButton: FC<Props> = ({ className }) => {
  const { editor } = useCurrentEditor()
  const { t } = useLocoTranslation()

  const isActive = isCursorInsideLink(editor)

  const isDisabled = !editor?.options.editable

  const setLink = () => {
    if (!editor) return null

    if (editor.isActive('link')) {
      editor.chain().focus()
    } else {
      editor.chain().focus().extendMarkRange('link').setLink({ href: '' }).run()
    }
  }

  const Button = isActive ? PrimaryButton : NeutralButton

  return (
    <Tooltip label={t('dashboard.simple_mail_editor.link')} {...TOOLTIP_STYLES}>
      <Button disabled={isDisabled} className={twMerge(className, 'w-[48px]')} onClick={setLink}>
        <LinkIcon className={twMerge(isActive && 'fill-white', isDisabled && 'fill-gray')} />
      </Button>
    </Tooltip>
  )
}

export default LinkButton
