import { DEFAULT_FONT_SIZE } from '../constans/default-styles'

export const getFontSizeValues = () => {
  const baseValues = Array.from({ length: 6 }, (_, i) => {
    const value = `${10 + i * 2}px`
    return {
      id: value,
      caption: value,
      customCaption: <div style={{ fontSize: value }}>{value}</div>,
    }
  })

  return [
    ...baseValues.slice(0, 3),
    {
      id: DEFAULT_FONT_SIZE,
      caption: DEFAULT_FONT_SIZE,
      customCaption: <div style={{ fontSize: DEFAULT_FONT_SIZE }}>{DEFAULT_FONT_SIZE}</div>,
    },
    ...baseValues.slice(3),
  ]
}
