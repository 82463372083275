import { FormInput, NeutralButton, PrimaryButton, SaveIcon } from '@systemeio/ui-shared'
import { BubbleMenu, useCurrentEditor } from '@tiptap/react'
import React, { useEffect, useRef, useState } from 'react'
import { isCursorInsideLink } from 'shared/components/dashboard-mail-editor/components/tip-tap-editor/utils/link'
import CancelIcon from '../icons/cancel-icon'
import LinkUnsetIcon from '../icons/link-unset-icon'
import PenIcon from '../icons/pen-icon'

const LinkBubbleMenu: React.FC = () => {
  const { editor } = useCurrentEditor()
  const editorLink = useRef<string | undefined>(editor?.getAttributes('link').href)
  const [newLink, setNewLink] = useState<string>('')
  const [isEditing, setIsEditing] = useState<boolean>(false)

  useEffect(() => {
    editorLink.current = editor?.getAttributes('link').href
    setNewLink(editorLink.current || '')
    setIsEditing(!editorLink.current)
  }, [editor?.state])

  const unsetLink = () => editor?.chain().focus().extendMarkRange('link').unsetLink().run()

  const onChangeLink = (value: string) => {
    setIsEditing(true)

    setNewLink(value)
  }

  const onSaveLink = () => {
    if (!editor) return

    setIsEditing(false)

    editor.chain().focus().extendMarkRange('link').setLink({ href: newLink }).run()
  }

  const onCancelLinkChange = () => {
    if (!editorLink.current) {
      unsetLink()
    }
    setNewLink(prevState => editorLink.current || prevState)
    setIsEditing(false)
  }

  const onHideMenu = () => {
    if (!editorLink.current) {
      unsetLink()
      setNewLink('')
    }
  }

  return (
    <BubbleMenu
      className="flex items-center gap-2 p-2 bg-white rounded-lg shadow-lg border border-gray-200"
      editor={editor}
      shouldShow={({ editor }) => isCursorInsideLink(editor)}
      tippyOptions={{
        duration: 0,
        placement: 'bottom',
        offset: [0, 10],
        followCursor: true,
        hideOnClick: true,
        onHide: onHideMenu,
      }}
    >
      <div className="flex gap-1 items-center">
        {isEditing ? (
          <>
            <FormInput
              value={newLink}
              placeholder={'Link URL'}
              onChange={e => onChangeLink(e.target.value)}
            />
            <PrimaryButton onClick={onSaveLink}>
              <SaveIcon />
            </PrimaryButton>
            <NeutralButton onClick={onCancelLinkChange}>
              <CancelIcon />
            </NeutralButton>
          </>
        ) : (
          <>
            <a
              href={newLink}
              target={'_blank'}
              className="text-blue underline px-3 w-[180px] overflow-hidden text-ellipsis whitespace-nowrap"
            >
              {newLink}
            </a>
            <PrimaryButton onClick={() => setIsEditing(true)}>
              <PenIcon className="fill-white" />
            </PrimaryButton>
            <NeutralButton onClick={unsetLink}>
              <LinkUnsetIcon />
            </NeutralButton>
          </>
        )}
      </div>
    </BubbleMenu>
  )
}

export default LinkBubbleMenu
