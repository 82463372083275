import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import { Color } from '@tiptap/extension-color'
import Document from '@tiptap/extension-document'
import FontFamily from '@tiptap/extension-font-family'
import { HardBreak } from '@tiptap/extension-hard-break'
import Heading from '@tiptap/extension-heading'
import History from '@tiptap/extension-history'
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Strike from '@tiptap/extension-strike'
import Text from '@tiptap/extension-text'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import { ReactNodeViewRenderer, mergeAttributes } from '@tiptap/react'
import { convertStyleObjectToCss } from 'shared/utils/style-helpers'
import CustomBulletListComponent from '../components/custom-bullet-list'
import CustomOrderedListComponent from '../components/custom-ordered-list'
import { defaultExtensions } from '../constans/default-extensions'
import {
  DEFAULT_COLOR,
  HEADING_STYLES,
  LINK_STYLES,
  PARAGRAPH_STYLES,
} from '../constans/default-styles'
import { EditorExtensionEnum } from '../enums/editor-extensions-enum'
import { BackgroundColor } from '../extentions/background-color'
import { FontSize } from '../extentions/font-size'
import { Indent } from '../extentions/indent'

export const getExtensions = (dependencies: EditorExtensionEnum[]) => {
  const extensionMap = {
    [EditorExtensionEnum.Document]: Document,
    [EditorExtensionEnum.Paragraph]: Paragraph.extend({
      renderHTML({ HTMLAttributes }) {
        return [
          'p',
          mergeAttributes(HTMLAttributes, {
            style: convertStyleObjectToCss(PARAGRAPH_STYLES),
          }),
          0,
        ]
      },
    }),
    [EditorExtensionEnum.Text]: Text,
    [EditorExtensionEnum.TextStyle]: TextStyle,
    [EditorExtensionEnum.FontFamily]: FontFamily.configure({
      types: ['textStyle'],
    }),
    [EditorExtensionEnum.Heading]: Heading.extend({
      renderHTML({ node, HTMLAttributes }) {
        const level = node.attrs.level as 2 | 3 | 4
        const styles = {
          ...HEADING_STYLES[level],
          color: DEFAULT_COLOR,
        }
        return [
          `h${level}`,
          mergeAttributes(HTMLAttributes, {
            style: `${convertStyleObjectToCss(styles)}; font-family: inherit;`,
          }),
          0,
        ]
      },
    }),
    [EditorExtensionEnum.Bold]: Bold,
    [EditorExtensionEnum.Italic]: Italic,
    [EditorExtensionEnum.Strike]: Strike,
    [EditorExtensionEnum.Underline]: Underline,
    [EditorExtensionEnum.BulletList]: BulletList.extend({
      addNodeView() {
        return ReactNodeViewRenderer(CustomBulletListComponent)
      },
    }),
    [EditorExtensionEnum.OrderedList]: OrderedList.extend({
      addNodeView() {
        return ReactNodeViewRenderer(CustomOrderedListComponent)
      },
    }),
    [EditorExtensionEnum.ListItem]: ListItem,
    [EditorExtensionEnum.TextAlign]: TextAlign.configure({
      types: ['heading', 'paragraph', 'listItem'],
      alignments: ['left', 'center', 'right', 'justify'],
    }),
    [EditorExtensionEnum.Link]: Link.extend({
      renderHTML({ HTMLAttributes }) {
        return [
          'a',
          mergeAttributes(HTMLAttributes, {
            style: convertStyleObjectToCss(LINK_STYLES),
          }),
          0,
        ]
      },
      inclusive: false,
    }).configure({
      openOnClick: false,
      autolink: true,
    }),
    [EditorExtensionEnum.History]: History,
    [EditorExtensionEnum.Color]: Color,
    [EditorExtensionEnum.BackgroundColor]: BackgroundColor,
    [EditorExtensionEnum.FontSize]: FontSize,
    [EditorExtensionEnum.Indent]: Indent,
    [EditorExtensionEnum.HardBreak]: HardBreak,
    [EditorExtensionEnum.Substitution]: null,
    [EditorExtensionEnum.SpecialCharacters]: null,
  }

  return dependencies
    .map(dep => extensionMap[dep])
    .filter((e): e is NonNullable<typeof e> => e !== null)
}

export const getExistingExtensions = (
  extensions: EditorExtensionEnum[],
): { [K in keyof typeof EditorExtensionEnum as `has${K}`]: boolean } =>
  defaultExtensions.reduce(
    (acc, key) => ({
      ...acc,
      [`has${EditorExtensionEnum[key]}`]: extensions.includes(key),
    }),
    {} as { [K in keyof typeof EditorExtensionEnum as `has${K}`]: boolean },
  )
