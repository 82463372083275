import { Editor } from '@tiptap/react'
import { TLocoType } from 'shared/hooks/use-loco-translation'
import { HEADING_STYLES } from '../constans/default-styles'
import { TextLevelEnum } from '../enums/text-level-enum'

export const getTextLevelValues = (t: TLocoType) => {
  const getCaptionLabel = (key: TextLevelEnum | string) => {
    switch (key) {
      case TextLevelEnum.Default:
      default:
        return t('dashboard.email.editor.text_level.paragraph')
      case TextLevelEnum.H2:
        return t('dashboard.email.editor.text_level.header_one')
      case TextLevelEnum.H3:
        return t('dashboard.email.editor.text_level.header_two')
      case TextLevelEnum.H4:
        return t('dashboard.email.editor.text_level.header_three')
    }
  }

  return [
    {
      id: TextLevelEnum.Default,
      caption: getCaptionLabel(TextLevelEnum.Default),
      customCaption: <div>{getCaptionLabel(TextLevelEnum.Default)}</div>,
    },
    {
      id: TextLevelEnum.H2,
      caption: getCaptionLabel(TextLevelEnum.H2),
      customCaption: (
        <div style={{ fontSize: HEADING_STYLES[TextLevelEnum.H2].fontSize }} className="font-bold">
          {getCaptionLabel(TextLevelEnum.H2)}
        </div>
      ),
    },
    {
      id: TextLevelEnum.H3,
      caption: getCaptionLabel(TextLevelEnum.H3),
      customCaption: (
        <div style={{ fontSize: HEADING_STYLES[TextLevelEnum.H3].fontSize }} className="font-bold">
          {getCaptionLabel(TextLevelEnum.H3)}
        </div>
      ),
    },
    {
      id: TextLevelEnum.H4,
      caption: getCaptionLabel(TextLevelEnum.H4),
      customCaption: (
        <div
          style={{ fontSize: HEADING_STYLES[TextLevelEnum.H4].fontSize }}
          className="font-semibold"
        >
          {getCaptionLabel(TextLevelEnum.H4)}
        </div>
      ),
    },
  ]
}

export const getCurrentTextLevelValue = (editor: Editor | null): TextLevelEnum | undefined => {
  if (!editor) return undefined

  const attributes = editor.getAttributes('heading')

  if (attributes.level) {
    switch (attributes.level) {
      case 2:
        return TextLevelEnum.H2
      case 3:
        return TextLevelEnum.H3
      case 4:
        return TextLevelEnum.H4
    }
  }

  if (editor.isActive('paragraph')) {
    return TextLevelEnum.Default
  }

  return undefined
}
