import { ColorPicker, NeutralButton, Tooltip } from '@systemeio/ui-shared'
import { useCurrentEditor } from '@tiptap/react'
import React, { FC } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { DEFAULT_COLOR, TOOLTIP_STYLES } from '../../constans/default-styles'
import PaletteIcon from '../../icons/palette-icon'

interface Props {
  className?: string
}

const TextColorButton: FC<Props> = ({ className }) => {
  const { editor } = useCurrentEditor()
  const { t } = useLocoTranslation()

  const onChangeTextColor = (color: string) => {
    if (!editor) return null

    editor.chain().setColor(color).run()
  }

  const color = editor?.getAttributes('textStyle').color || DEFAULT_COLOR

  const isDisabled = !editor?.options.editable

  return (
    <div className={className}>
      {!isDisabled ? (
        <Tooltip label={t('dashboard.simple_mail_editor.font_color')} {...TOOLTIP_STYLES}>
          <ColorPicker color={color} onChange={onChangeTextColor}>
            {open => (
              <div
                className={twMerge(
                  'inline-flex items-center justify-center px-4 py-2.5 text-sm font-medium rounded-lg min-h-[42px] w-[48px]',
                  open
                    ? 'text-white bg-blue hover:bg-blue-300'
                    : 'text-darkblue bg-white hover:bg-white border border-gray/30 hover:border-blue hover:text-blue',
                )}
              >
                <PaletteIcon
                  style={{
                    fill:
                      color.toString().toLowerCase() !== '#ffffff'
                        ? color
                        : open
                        ? 'white'
                        : 'black',
                  }}
                />
              </div>
            )}
          </ColorPicker>
        </Tooltip>
      ) : (
        <NeutralButton disabled={isDisabled}>
          <PaletteIcon className={twMerge(isDisabled && 'fill-gray')} />
        </NeutralButton>
      )}
    </div>
  )
}

export default TextColorButton
