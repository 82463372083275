export const DEFAULT_COLOR = '#000000'
export const DEFAULT_FONT_SIZE = '15px'
export const DEFAULT_BG_COLOR = '#ffffff'
export const DEFAULT_MY = '16px'

export const PARAGRAPH_STYLES = {
  marginTop: DEFAULT_MY,
  marginBottom: DEFAULT_MY,
  color: DEFAULT_COLOR,
  fontSize: DEFAULT_FONT_SIZE,
}

export const HEADING_STYLES = {
  2: {
    marginTop: '20.25px',
    marginBottom: '0',
    fontSize: '22.5px',
  },
  3: {
    marginTop: '0',
    marginBottom: '0',
    fontSize: '17.55px',
  },
  4: {
    marginTop: '0',
    marginBottom: '0',
    fontSize: DEFAULT_FONT_SIZE,
  },
}

export const LIST_STYLES = {
  marginTop: DEFAULT_MY,
  marginBottom: DEFAULT_MY,
  paddingLeft: '40px',
  color: DEFAULT_COLOR,
}

export const LINK_STYLES = {
  textDecorationLine: 'underline',
  color: 'rgb(17 85 204 / var(--tw-text-opacity))',
}

export const TOOLTIP_STYLES = {
  hoverOpenDelay: 900,
  popoverClassName: 'px-2 py-1 shadow-md [--anchor-gap:5px] z-[9999]',
}
