import { SelectedCategoriesEnum } from 'shared/components/dashboard-mail-editor/components/tip-tap-editor/enums/selected-categories-enum'
import { TLocoType } from 'shared/hooks/use-loco-translation'

export const getCharactersCategoriesData = (t: TLocoType) => {
  return [
    {
      caption: t('dashboard.email.editor.special_characters.select_text'),
      id: SelectedCategoriesEnum.Text,
    },
    {
      caption: t('dashboard.email.editor.special_characters.select_currency'),
      id: SelectedCategoriesEnum.Currency,
    },
    {
      caption: t('dashboard.email.editor.special_characters.select_all'),
      id: SelectedCategoriesEnum.All,
    },
  ]
}
