import { NeutralButton, Tooltip } from '@systemeio/ui-shared'
import { useCurrentEditor } from '@tiptap/react'
import { FC } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { TOOLTIP_STYLES } from '../../constans/default-styles'
import { IndentProps } from '../../extentions/indent'
import IndentIcon from '../../icons/indent-icon'
import OutdentIcon from '../../icons/outdent-icon'

interface Props {
  className?: string
}

const IndentButtons: FC<Props> = ({ className }) => {
  const { editor } = useCurrentEditor()
  const { t } = useLocoTranslation()

  const getCurrentIndent = () => {
    const paragraph = editor?.getAttributes('paragraph').indent
    const heading = editor?.getAttributes('heading').indent
    const bulletList = editor?.getAttributes('bulletList').indent
    const orderedList = editor?.getAttributes('orderedList').indent

    return paragraph || heading || bulletList || orderedList || 0
  }

  const currentIndent = getCurrentIndent()

  const isMinDisabled = !editor?.options.editable || currentIndent <= IndentProps.min
  const isMaxDisabled = !editor?.options.editable || currentIndent >= IndentProps.max

  const onClickOutdent = () => editor?.commands.outdent()

  const onClickIndent = () => editor?.commands.indent()

  return (
    <div className={twMerge('flex gap-1', className)}>
      <Tooltip label={t('dashboard.simple_mail_editor.increase_intent')} {...TOOLTIP_STYLES}>
        <NeutralButton onClick={onClickOutdent} disabled={isMinDisabled}>
          <OutdentIcon className={isMinDisabled ? 'fill-gray' : 'fill-black'} />
        </NeutralButton>
      </Tooltip>
      <Tooltip label={t('dashboard.simple_mail_editor.decrease_intent')} {...TOOLTIP_STYLES}>
        <NeutralButton onClick={onClickIndent} disabled={isMaxDisabled}>
          <IndentIcon className={isMaxDisabled ? 'fill-gray' : 'fill-black'} />
        </NeutralButton>
      </Tooltip>
    </div>
  )
}

export default IndentButtons
