import { NeutralButton, PrimaryButton, Tooltip } from '@systemeio/ui-shared'
import { useCurrentEditor } from '@tiptap/react'
import { FC, useEffect, useState } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { TOOLTIP_STYLES } from '../../constans/default-styles'
import { EditorExtensionEnum } from '../../enums/editor-extensions-enum'
import { ListEnum } from '../../enums/list-enum'
import { useShortcuts } from '../../hooks/use-shortcuts'
import { getExistingExtensions } from '../../utils/get-extensions'
import { getListIcon, setList } from '../../utils/list'

interface Props {
  extensions: EditorExtensionEnum[]
  className?: string
}

const ListButtons: FC<Props> = ({ extensions, className }) => {
  const { editor } = useCurrentEditor()
  const { getShortcut, rawShortcuts } = useShortcuts()
  const { t } = useLocoTranslation()

  const [listButtons, setListButtons] = useState<ListEnum[]>([])

  useEffect(() => {
    const buttons = [
      extensions.includes(EditorExtensionEnum.BulletList) ? ListEnum.BulletList : null,
      extensions.includes(EditorExtensionEnum.OrderedList) ? ListEnum.OrderedList : null,
    ].filter(Boolean) as ListEnum[]

    setListButtons(buttons)
  }, [extensions])

  const isDisabled = !editor?.options.editable

  const renderButton = (listType: ListEnum) => {
    const isActive = editor?.isActive(listType)

    const onClickListButton = () => setList({ editor, listType })

    const Button = isActive ? PrimaryButton : NeutralButton
    const Icon = getListIcon({ listType })

    return (
      <Tooltip
        label={`${t(`dashboard.simple_mail_editor.${listType}`)} (${getShortcut(
          rawShortcuts.list[listType],
        )})`}
        {...TOOLTIP_STYLES}
      >
        <Button
          disabled={isDisabled}
          className={'w-[48px]'}
          key={listType}
          onClick={onClickListButton}
        >
          <Icon className={twMerge(isActive && 'fill-white', isDisabled && 'fill-gray')} />
        </Button>
      </Tooltip>
    )
  }

  const { hasOrderedList, hasBulletList, hasListItem } = getExistingExtensions(extensions)

  if (!hasListItem && !(hasOrderedList || hasBulletList)) return null

  return (
    <div className={twMerge('flex gap-1', className)}>
      {listButtons.map(list => renderButton(list))}
    </div>
  )
}

export default ListButtons
