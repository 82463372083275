import { Editor } from '@tiptap/react'
import { TextFormatEnum } from '../enums/text-format-enum'
import BoldIcon from '../icons/bold-icon'
import ItalicIcon from '../icons/italic-icon'
import StrikethroughIcon from '../icons/strikethrough-icon'
import UnderlineIcon from '../icons/underline-icon'

export const setTextFormat = ({
  formatType,
  editor,
}: {
  formatType: TextFormatEnum
  editor: Editor | null
}) => {
  if (!editor) return

  switch (formatType) {
    case TextFormatEnum.Bold:
      editor.chain().focus().toggleBold().run()
      break
    case TextFormatEnum.Italic:
      editor.chain().focus().toggleItalic().run()
      break
    case TextFormatEnum.Underline:
      editor.chain().focus().toggleUnderline().run()
      break
    case TextFormatEnum.Strike:
      editor.chain().focus().toggleStrike().run()
      break
  }
}

export const getTextFormatIcon = ({ formatType }: { formatType: TextFormatEnum }) => {
  switch (formatType) {
    case TextFormatEnum.Bold:
      return BoldIcon
    case TextFormatEnum.Italic:
      return ItalicIcon
    case TextFormatEnum.Underline:
      return UnderlineIcon
    case TextFormatEnum.Strike:
      return StrikethroughIcon
  }
}
