import { NodeViewProps } from '@tiptap/core'
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'
import React, { FC, useEffect, useState } from 'react'
import { LIST_STYLES } from '../constans/default-styles'
import { getOrderedListStyleType } from '../utils/list'

const CustomOrderedList: FC<NodeViewProps> = ({ getPos, editor }) => {
  const [realDepth, setRealDepth] = useState(0)

  useEffect(() => {
    const calculateRealDepth = () => {
      let depth = 0
      let $pos = editor.state.doc.resolve(getPos())

      for (let d = $pos.depth; d > 0; d--) {
        const node = $pos.node(d)
        if (node.type.name === 'orderedList') {
          depth++
        }
      }

      return depth
    }

    setRealDepth(calculateRealDepth())
  }, [getPos, editor.state])

  return (
    <NodeViewWrapper
      as={'ol'}
      style={{
        ...LIST_STYLES,
        listStyleType: getOrderedListStyleType(realDepth),
      }}
    >
      <NodeViewContent />
    </NodeViewWrapper>
  )
}

export default CustomOrderedList
