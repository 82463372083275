import { Tooltip } from '@systemeio/ui-shared'
import { ArrowDownIcon, NeutralButton } from '@systemeio/ui-shared'
import { useCurrentEditor } from '@tiptap/react'
import React from 'react'
import { ListBoxSelect } from 'shared/components/form-select/listbox-select'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { twMerge } from 'tailwind-merge'
import { TOOLTIP_STYLES } from '../../constans/default-styles'
import { TextAlignEnum } from '../../enums/text-align-enum'
import { useShortcuts } from '../../hooks/use-shortcuts'
import AlignCenterIcon from '../../icons/align-center-icon'
import AlignJustifyIcon from '../../icons/align-justify-icon'
import AlignLeftIcon from '../../icons/align-left-icon'
import AlignRightIcon from '../../icons/align-right-icon'
import { getCurrentTextAlignValue, getTextAlignValues } from '../../utils/text-align'

interface Props {
  className?: string
}

const TextAlignSelect: React.FC<Props> = ({ className }) => {
  const { editor } = useCurrentEditor()
  const { getShortcut, rawShortcuts } = useShortcuts()
  const { t } = useLocoTranslation()

  const onChangeTextAlign = (value: TextAlignEnum | undefined) => {
    if (!editor || !value) return null

    setTimeout(() => {
      editor.commands.focus()
    }, 0)

    editor.chain().focus().setTextAlign(value).run()
  }

  const selectedValue = getCurrentTextAlignValue(editor)

  const isDisabled = !editor?.options.editable

  const renderValueIcon = () => {
    const className = twMerge(isDisabled && 'fill-gray')

    switch (selectedValue) {
      default:
      case TextAlignEnum.Left:
        return <AlignLeftIcon className={className} />
      case TextAlignEnum.Right:
        return <AlignRightIcon className={className} />
      case TextAlignEnum.Center:
        return <AlignCenterIcon className={className} />
      case TextAlignEnum.Justify:
        return <AlignJustifyIcon className={className} />
    }
  }

  return (
    <div className={className}>
      <Tooltip
        label={t('dashboard.simple_mail_editor.text_align')}
        anchor="left"
        {...TOOLTIP_STYLES}
      >
        <ListBoxSelect
          disabled={isDisabled}
          value={getCurrentTextAlignValue(editor)}
          data={getTextAlignValues(selectedValue)}
          onChange={onChangeTextAlign}
          optionClassName={'hover:fill-white'}
        >
          {open => (
            <NeutralButton disabled={isDisabled} className="w-[80px]">
              {renderValueIcon()}
              <ArrowDownIcon
                className={twMerge(
                  'transition-transform ease-linear duration-200',
                  open && 'rotate-180',
                  isDisabled && 'stroke-gray',
                )}
              />
            </NeutralButton>
          )}
        </ListBoxSelect>
      </Tooltip>
    </div>
  )
}

export default TextAlignSelect
